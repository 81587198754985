<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form [position]="getFormPos(form, 0)" [theme]="theme" [zIndex]="999" [width]="mapWidth" [height]="mapHeight"
  [autoOpen]="true" [showCloseButton]="false" style="margin-top: 24px">
  <div hidden="true"></div>
  <div class="form">
    <div>
      <app-header #header [exportar]="true" [periodo]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
        [noLibre]="true" (eventExportar)="onExportar()" (eventFilter)="onAceptar()" (eventResetFilter)="onResetFilter()"
        (eventBuscador)="onBuscar()">
      </app-header>
    </div>
    <div style="width: 100% !important;height: calc(100% - 40px) !important; position: absolute; z-index: 999;">
      <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'99%'" [source]="dataAdapter" [columnsresize]="true"
        [sortable]="true" [showrowlines]="false" [showcolumnlines]="true" [columns]="columns" [altrows]="true"
        [filterable]="true" [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
        [columnsheight]="20" [localization]="langGrid" [groupable]="true" [groupsrenderer]="groupsrenderer"
        (onFilter)="onfilter($event)">
      </jqxGrid>
    </div>
  </div>
</jqxWindow>