import { Component, OnInit, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { AlarmaCerraduraModel } from 'src/app/services/cerraduras/models/alarmaCerradura';
import { DateIdentificacionModel } from 'src/app/services/cerraduras/models/dateIdentificacion';

import { ModeloSelectComponent } from 'src/app/components/modelo-select/modelo-select.component';
import { SsoService } from 'src/app/services/sso/sso.service';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { Utils } from 'src/app/utils/utils';
import { PeriodoSelectComponent } from 'src/app/components/periodo-select/periodo-select.component';

@Component({
  selector: 'app-listado-alarma',
  templateUrl: './listado-alarma.component.html',
  styleUrls: ['./listado-alarma.component.css']
})
export class ListadoAlarmaComponent extends CustomForms implements OnInit {
  private componentRef = null;
  public theme = environment.tema;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') myGrid: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;

  // objects
  dateFin = new Date();
  dateInicio = new Date();
  alarmas: AlarmaCerraduraModel[] = [];
  elementosSelect: ElementoModel[] = [];
  alarma: AlarmaCerraduraModel = new AlarmaCerraduraModel;
  dateIdentificacion: DateIdentificacionModel = new DateIdentificacionModel();
  public ocultar: boolean = true;

  // grid
  dataAdapter: any;
  columns: any[] = [];
  source: any;
  public langGrid = JqWidgets.getLocalization('es');
  editrow: number = -1;

  mapHeight;
  mapWidth;

  static _this: any
  checkAlarms: Boolean = false;
  showLoader: boolean = true;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(private ssoService: SsoService,
    private cerraduraService: CerraduraService,) {
    super();
    ListadoAlarmaComponent._this = this;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.mapHeight = document.getElementById('map-container').offsetHeight - 24;
    this.getAlarmasByCerraduras();

  }

  /**Inicializa el componente
  */
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  ngAfterViewInit() {
    this.addCustomForm(this.form, false);
    this.onAceptar();
    if (this.myGrid.getrows().length == 0) {
      this.myGrid.showemptyrow(true);
    }

  }

  getAlarmasByCerraduras() {
    this.columns = [
      {
        text: this.translate('Fecha'), columntype: 'datetimeinput', filtertype: 'date', datafield: this.translate('Fecha'), width: 140, cellsrenderer: this.renderDate,
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
              NumberUtils.format(aggregates["Total"], 0) + '</div>';
          }
          return renderstring;
        }
      },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Ns_movisat'), width: 110 },
      { text: this.translate('Alarma'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Alarma'), width: 90 },
      { text: this.translate('Bateria_porcentaje'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Bateria_porcentaje'), width: 90, cellsrenderer: this.numberrendererDecimales, },
      { text: this.translate('Temperatura_grados'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Temperatura_grados'), width: 100, cellsrenderer: this.numberrendererDecimales, },
      { text: this.translate('Voltaje_vatios'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Voltaje_vatios'), width: 70, cellsrenderer: this.numberrendererDecimales, },
      { text: this.translate('Nombre_elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Nombre_elemento'), width: 120 },
      { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Marca'), width: 70 },
      { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Modelo'), width: 70 },
      { text: this.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Matricula'), width: 90 },
      { text: this.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Observaciones'), sortable: false, filterable: false, width: 100 },
      { text: this.translate('Direccion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Direccion'), width: 100 },
      { text: this.translate('Municipio'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Municipio'), width: 90 },
      { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Poblacion'), width: 90 },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];


    this.source = {
      datatype: 'json',
      dataFields: [
        { name: this.translate('Fecha'), type: 'date', map: 'fecha' },
        { name: this.translate('Ns_movisat'), type: 'string', map: 'nsCerradura' },
        { name: this.translate('Alarma'), type: 'string', map: 'alarma' },

        { name: this.translate('Bateria_porcentaje'), type: 'number', map: 'bateriaPorcentaje' },
        { name: this.translate('Temperatura_grados'), type: 'number', map: 'temperatura' },
        { name: this.translate('Voltaje_vatios'), type: 'number', map: 'voltaje' },

        { name: this.translate('Nombre_elemento'), type: 'string', map: 'nombreElemento' },
        { name: this.translate('Marca'), type: 'string', map: 'marcaElemento' },
        { name: this.translate('Modelo'), type: 'string', map: 'modeloElemento' },
        { name: this.translate('Matricula'), type: 'string', map: 'matriculaElemento' },
        { name: this.translate('Observaciones'), type: 'string', map: 'observacionesElemento' },

        { name: this.translate('Direccion'), type: 'string', map: 'direccionElemento' },
        { name: this.translate('Municipio'), type: 'string', map: 'municipioElemento' },
        { name: this.translate('Poblacion'), type: 'string', map: 'poblacionElemento' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.alarmas
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);

    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    setTimeout(() => {
      this.myGrid.setOptions({
        rowsheight: 20,
        columnsheight: 20
      })
    }, 200);

  }

  renderDate(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      let date = new Date(value);
      return '<div style="margin-left: 4px; margin-top: 1.5px;">' + DateUtils.formatDateTimeShort(date, true) + '</div>';
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 1.5px; margin-top: 4px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 1.5px; margin-top: 4px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value !== null) {
      if (columnfield == AppComponent.translate('Temperatura_grados')) {
        value = value / 10;
        value = NumberUtils.format(value, 2);
      } else if (columnfield == AppComponent.translate('Voltaje_vatios')) {
        value = value / 100;
        value = NumberUtils.format(value, 2);
      } else {
        value = NumberUtils.format(value, 2);
      }
      return '<div style="margin-right: 1.5px; margin-top: 4px; text-align: right;">' + value + '</div>';
    } else {
      return '<div style="margin-right: 1.5px; margin-top: 4px; text-align: right;"></div>';
    }
  }

  selecElement(listElement: any) {
    this.elementosSelect = listElement;
    if (this.elementosSelect.length <= 0 && this.source) {
      this.alarmas = [];
      this.source.localdata = this.alarmas;
      this.myGrid.updatebounddata();
    }
  }

  onAceptar() {
    this.header.searchInput['nativeElement'].value = '';
    this.dateInicio = this.header.periodoSelect.getFechaIni();
    this.dateFin = this.header.periodoSelect.getFechaFin();
    this.dateIdentificacion.Desde = this.dateInicio;
    this.dateIdentificacion.Hasta = this.dateFin;
    this.searchAlarmas();
  }

  // resetea los filtros
  onResetFilter() {
    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.desde.setHours(0, 0, 0);
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.hasta.setHours(23, 59, 59);
    PeriodoSelectComponent._this.dateForm.get('desde').setValue(new Date());
    PeriodoSelectComponent._this.dateForm.get('hasta').setValue(new Date());
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    this.header.searchInput['nativeElement'].value = '';
    this.header.checkAlarms.checked(false);
    this.checkAlarms = false;
    this.onAceptar();
  }

  onCollapse(event) {
    ModeloSelectComponent._this.onCollapse();
  }

  onExpand(event) {
    ModeloSelectComponent._this.onExpand();
  }

  async searchAlarmas() {
    this.alarmas = await this.cerraduraService.getAlarmas(this.dateIdentificacion, this.checkAlarms);

    this.alarmas.forEach(element => {
      element.alarma = '';
      if (element.bateria == 1) {
        element.alarma = AppComponent.translate('Alarma_bateria_baja');
      } else if (element.bateria == 2) {
        element.alarma = AppComponent.translate('Alarma_bateria_critica');
      } else if (element.bateria == 3) {
        element.alarma = AppComponent.translate('Cambio_bateria');
      } else if (element.temperaturaElevado == true) {
        element.alarma = AppComponent.translate('Temperatura_elevado');
      } else if (element.vaciado == true) {
        element.alarma = AppComponent.translate('Vaciado');
      } else if (element.diagnostico == true) {
        /* element.listDiagnostico.forEach(diag => {
          element.alarma += element.alarma != null ? '; ' + diag : diag;
        }); */
        element.alarma = AppComponent.translate('Diagnostico');
      } else if (element.puertaAbierta == true) {
        element.alarma = AppComponent.translate('Puerta_abierta');
      } else if (element.puertaViolentada == true) {
        element.alarma = AppComponent.translate('Puerta_violentada');
      } else if (element.vandalismo == true) {
        element.alarma = AppComponent.translate('Vandalismo');
      }
    });

    this.source.localdata = this.alarmas;
    this.myGrid.sortby(this.translate('Fecha'), 'desc')
    this.myGrid.updatebounddata('data');
    this.onBuscar();
    // this.resizeColumns();
    this.showLoader = await Utils.renderRowGridText();
  }

  onCheckAlarms(event: any) {
    this.dateInicio = this.header.periodoSelect.getFechaIni();
    this.dateFin = this.header.periodoSelect.getFechaFin();
    this.dateIdentificacion.Desde = this.dateInicio;
    this.dateIdentificacion.Hasta = this.dateFin;
    this.checkAlarms = event;
    this.searchAlarmas();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.alarmas.forEach(alarm => {
      if (
        alarm?.nsCerradura?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        alarm?.alarma?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (alarm?.bateriaPorcentaje + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (alarm?.temperatura + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (alarm?.voltaje + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        alarm?.nombreElemento?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        alarm?.marcaElemento?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        alarm?.modeloElemento?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        alarm?.matriculaElemento?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        alarm?.observacionesElemento?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        alarm?.direccionElemento?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        alarm?.municipioElemento?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        alarm?.poblacionElemento?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        alarm['selec'] = 'selec';
      } else {
        alarm['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.myGrid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.myGrid.addfilter('selec', filtergroup);
    }
    this.myGrid.applyfilters();
    this.myGrid.updatebounddata('data');
  }

  onExportar() {
    const json = JSON.parse(JSON.stringify(this.myGrid.getrows()));
    json.forEach(element => {
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;

      if (element[this.translate('Fecha')]) {
        element[this.translate('Fecha')] = DateUtils.formatDateTimeShort(new Date(element[this.translate('Fecha')]), true)
      }

      element[this.translate('Temperatura_grados')] = element[this.translate('Temperatura_grados')] / 10;
      element[this.translate('Temperatura_grados')] = '' + NumberUtils.format(element[this.translate('Temperatura_grados')], 2);

      element[this.translate('Voltaje_vatios')] = element[this.translate('Voltaje_vatios')] / 100;
      element[this.translate('Voltaje_vatios')] = '' + NumberUtils.format(element[this.translate('Voltaje_vatios')], 2);

      element[this.translate('Bateria_porcentaje')] = '' + NumberUtils.format(element[this.translate('Bateria_porcentaje')], 2);
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + AppComponent.translate('Historico_alarmas_IoT') + '.xlsx');
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    ListadoAlarmaComponent._this = null;
  }

  resizeColumns() {
    if (this.alarmas.length != 0) {
      // Asumiendo que this.myGrid.attrColumns está listo para ser usado inmediatamente.
      this.myGrid.attrColumns.forEach((column: any) => {
        try {
          if (column.datafield !== this.translate('Temperatura_grados') &&
            column.datafield !== this.translate('Voltaje_vatios') &&
            column.datafield !== this.translate('Bateria_porcentaje')
          ) {
            this.myGrid.autoresizecolumn(column.datafield, column.columntype);
          }
        } catch (e) { }
      });

      // Lógica adicional, si es necesario, después de redimensionar las columnas.
      Utils.renderSizeGrid(
        this.myGrid,
        500,
        [this.translate('Temperatura_grados'), this.translate('Voltaje_vatios'), this.translate('Bateria_porcentaje')]
      );
    }
  }
}
